<template>
  <div class="experience_order">
    <div class="order_status_nav">
      <div
        v-for="item in statusLiat"
        :key="item.id"
        :class="[currentOrderType == item.id ? 'active' : '']"
        @click="changeOrderType(item.id)"
      >
        {{ item.status }}
        <span v-if="item.status == '待审核'" class="customer_num">{{
          $store.state.info_check
        }}</span>
      </div>
    </div>
    <div class="search_wrap">
      <el-row class="mb-4">
        <button class="mb-4-btn" type="default" @click="putshelf">上架</button>
        <button class="mb-4-btn" type="default" @click="outshelf">下架</button>
        <button class="mb-4-btn" type="default" @click="handledelete">
          删除
        </button>
      </el-row>
      <div>
        <el-input
          placeholder="请输入工厂名称或电话"
          v-model="payload.filter"
          class="input-with-select"
        >
          <el-button @click="searchBtn" slot="append">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="manage_order_list_warp">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @filter-change="handleFilterChange"
        border
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="工厂名称">
          <template v-slot="scope">
            <div class="rowapper">
              <img :src="scope.row.picture" alt="" class="titleimg" />
              <div class="des">
                <div class="name" @click="handlesee(scope.row)">
                  {{ scope.row.fact_name }}
                </div>
                <div class="addres">{{ scope.row.address }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="name" label="联系人" />
        <el-table-column property="phone" label="联系方式" />
        <el-table-column property="examine_name" label="审核人" />
        <el-table-column label="审核状态">
          <template v-slot="scope">
            <div>
              <span
                class="cirle"
                :style="{ background: handleFactoryStatus(scope.row).color }"
              ></span>
              <span :style="{ color: handleFactoryStatus(scope.row).color }">{{
                handleFactoryStatus(scope.row).text
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="create_time"
          label="提交时间"
          sortable
          show-overflow-tooltip
        />
        <el-table-column
          property="update_time"
          label="处理时间"
          sortable
          show-overflow-tooltip
        />
        <el-table-column label="提交信息">
          <template v-slot="scope">
            <span @click="handlesee(scope.row)" style="color: #155bd4"
              >查看详情</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-popover>
              <template #reference>
                <span style="color: #155bd4">处理</span>
              </template>
              <div
                :style="{
                  color: items.use ? '#000' : '#ccc',
                  cursor: 'pointer',
                  padding: '4px 0',
                }"
                v-for="(items, i) in handleFactoryStatus(scope.row).funList"
                :key="i"
                @click="items['fun'](items.use ? scope.row : '')"
              >
                {{ items.lable }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="查看详情"
      :visible.sync="orderDetalDialogShow"
      width="30%"
    >
      <div class="orderDetalDialog_wrap">
        <p><span>订单编号：</span>{{ currentOrderInfo.order_id }}</p>
        <p><span>回访者：</span>{{ currentOrderInfo.revisit_name }}</p>
        <p><span>回访时间：</span>{{ currentOrderInfo.revisit_time }}</p>
        <p><span>回访详情：</span>{{ currentOrderInfo.revisit_info }}</p>
        <p>
          <span>线索状态：</span
          >{{ currentOrderInfo.share_status >= 2 ? '有效' : '无效' }}
        </p>
        <p><span>分配给：</span>{{ currentOrderInfo.be_assigned_name }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="orderDetalDialogShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="close"
      title="处理回访"
      :visible.sync="handleDialogShow"
      width="30%"
    >
      <div class="handleDialog_wrap">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="回访者" prop="revisit_name">
            <el-input v-model="ruleForm.revisit_name"></el-input>
          </el-form-item>
          <el-form-item label="选择回访时间" prop="revisit_time" required>
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.revisit_time"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="回访详情" prop="revisit_info">
            <el-input
              type="textarea"
              v-model="ruleForm.revisit_info"
            ></el-input>
          </el-form-item>
          <el-form-item label="线索是否有效" prop="efficient">
            <el-select
              v-model="ruleForm.efficient"
              class="m-2"
              placeholder="Select"
              size="large"
            >
              <el-option :key="1" label="有效" :value="1" />
              <el-option :key="0" label="无效" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="分配给" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="payload.page"
        :page-sizes="[9, 18, 27, 36]"
        :page-size="payload.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total_count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      FactoryStatus: {
        '0-0': {
          text: '待审核',
          funList: [
            {
              fun: this.examineAdoptFun,
              lable: '审核通过',
              use: true,
            },
            {
              fun: this.examineRejectFun,
              lable: '审核驳回',
              use: true,
            },
            {
              fun: this.handleClick,
              lable: '下架',
              use: false,
            },
            {
              fun: this.rowDelete,
              lable: '删除',
              use: false,
            },
          ],
          color: '#ff9b71',
        },
        '1-1': {
          text: '展示中',
          funList: [
            {
              fun: this.handleClick,
              lable: '上架',
              use: false,
            },
            {
              fun: this.handleClick,
              lable: '下架',
              use: true,
            },
            {
              fun: this.rowDelete,
              lable: '删除',
              use: false,
            },
          ],
          color: '#1890ff',
        },
        '1-0': {
          text: '未上架',
          funList: [
            {
              fun: this.handleClick,
              lable: '上架',
              use: true,
            },
            {
              fun: this.rowDelete,
              lable: '删除',
              use: true,
            },
          ],
          color: '#acacac',
        },
        '2-0': {
          text: '未通过',
          funList: [
            {
              fun: this.handleClick,
              lable: '上架',
              use: false,
            },
            {
              fun: this.rowDelete,
              lable: '删除',
              use: true,
            },
          ],
          color: '#f43934',
        },
      },
      //状态筛选
      statusLiat: [
        {
          id: 0,
          status: '全部',
        },
        {
          id: 1,
          status: '待审核',
        },
        {
          id: 2,
          status: '展示中',
        },
        {
          id: 3,
          status: '未上架',
        },
        {
          id: 4,
          status: '未通过',
        },
      ],
      //云工厂列表入参
      payload: {
        examine_status: '',
        limit: 9,
        page: 1,
        status: '',
        filter: '',
      },
      // 多选的内容
      multipleSelection: [],
      // 创建者筛选list
      creatNameList: [],
      //工厂类型筛选
      certificationFilter: [
        { text: '云工厂', value: 1 },
        { text: '普通工厂', value: 2 },
      ],
      close: false,
      ruleForm: {
        revisit_name: '',
        revisit_time: '',
        revisit_info: '',
        efficient: 1,
        phone: '',
      },
      rules: {
        revisit_name: [
          { required: true, message: '请输入回访者', trigger: 'blur' },
        ],
        revisit_time: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'blur',
          },
        ],
        revisit_info: [
          { required: true, message: '请输入回访详情', trigger: 'blur' },
        ],
        efficient: {
          required: true,
          message: '请选择是否有效',
          trigger: 'blur',
        },
      },
      currentOrderType: 0,
      tableData: [],
      handleDialogShow: false,
      orderDetalDialogShow: false,
      currentOrderInfo: {},
      form: {
        page: 1,
        limit: 12,
      },
      total_count: 13,

      search_type: 'phone',
    }
  },
  computed: {
    handleFactoryStatus(row) {
      console.log('==row==', row)
      return (row) => {
        /**
         * examine_status   status
         *  0/未审查        0/未上架
         *  1/已过          1/已上架
         *  2/未过
         *
         */
        let type = `${row.examine_status}-${row.status}`
        return this.FactoryStatus[type]
      }
    },
    orderNums() {
      return this.$store.state.tatal_order_num.experience || {}
    },
    orderStatusStyle() {
      return (status) => {
        return {
          width: '8px',
          height: '8px',
          'border-radius': '50%',
          backgroundColor: status ? '#00a854' : '#bfbfbf',
          display: 'inline-block',
          marginRight: '5px',
        }
      }
    },
  },
  methods: {
    //删除
    rowDelete(row) {
      if (row.status == 1) {
        this.$message.warning('已上架的数据不能删除')
        return
      }
      if (row.status == 0) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$request.deletefactoryasync(
              {
                id: [row.id],
              },
              (res) => {
                if (res.status == 1) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!',
                  })
                  this.getList()
                  // this.getExamineStatusCount()
                } else {
                  this.$message.error('上架的不能删除,删除失败')
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      }
    },
    // 上下架
    handleClick(row) {
      // 下架
      if (row.status == 1) {
        this.$confirm('此操作将会直接下架厂家信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$request.onOrOffTheShelfCloudFactory(
              {
                id: [row.id],
                status: 0,
              },
              (res) => {
                if (res.status == 1) {
                  this.getList()
                  this.$message({
                    type: 'success',
                    message: '下架成功!',
                  })
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: `取消下架`,
            })
          })
      }
      // 上架
      if (row.status == 0) {
        this.$confirm('此操作将会直接上架厂家信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$request.onOrOffTheShelfCloudFactory(
              {
                id: [row.id],
                status: 1,
              },
              (res) => {
                if (res.status == 1) {
                  this.getList()
                  this.$message({
                    type: 'success',
                    message: '上架成功!',
                  })
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: `取消上架`,
            })
          })
      }
    },
    // 查看详情
    handlesee(row) {
      this.$router.push({
        path: 'factory-info',
        query: {
          id: row.id,
        },
      })
    },
    // 多选框
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('val', val)
    },
    // 表格筛选
    handleFilterChange(value) {
      console.log(value)
    },
    // 搜索按钮
    searchBtn() {
      console.log('filter', this.payload.filter)
      this.getList()
    },
    handleSizeChange(value) {
      this.payload.limit = value
      this.getList()
    },
    // 回访取消
    cancle(name) {
      this.handleDialogShow = false
      this.$refs[name].resetFields()
    },
    // 回访确定
    submitForm(name) {
      let payload = {
        customer_id: this.currentOrderInfo.id,
        ...this.ruleForm,
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$request.toVisit(payload, (res) => {
            if (res.status == 1) {
              this.getList(true)
            }
          })
          this.getList()
          this.handleDialogShow = false
          this.$refs[name].resetFields()
          this.ruleForm = {
            revisit_name: '',
            revisit_time: '',
            revisit_info: '',
          }
        } else {
          return false
        }
      })
    },
    changeOrderType(value) {
      console.log('===', value)
      this.payload.filter = ''
      this.form.page = 1
      this.currentOrderType = value
      switch (value) {
        case 0:
          this.payload.examine_status = ''
          this.payload.page = 1
          this.payload.status = ''
          break
        case 1:
          this.payload.examine_status = 0
          this.payload.page = 1
          this.payload.status = 0
          break
        case 2:
          this.payload.examine_status = 1
          this.payload.page = 1
          this.payload.status = 1
          break
        case 3:
          this.payload.examine_status = 1
          this.payload.page = 1
          this.payload.status = 0
          break
        case 4:
          this.payload.examine_status = 2
          this.payload.page = 1
          this.payload.status = 0
          console.log('this.payload', this.payload)
          break
      }
      console.log('this.payload', this.payload)
      this.getList()
    },
    //审核通过
    examineAdoptFun(row) {
      // 审核通过
      if (!row) {
        return
      }
      this.$request.setExamineStatus(
        { id: [row.id], examine_status: 1 },
        (res) => {
          if (res.status == 1) {
            this.getList()
            this.$store.dispatch('getInfocheck')
          }
          this.$message({
            type: res.status == 1 ? 'success' : 'error',
            message: res.msg,
          })
          // this.getExamineStatusCount()
        }
      )
    },
    //审核驳回
    examineRejectFun(row) {
      if (!row) {
        return
      }
      this.$request.setExamineStatus(
        { id: [row.id], examine_status: 2 },
        (res) => {
          if (res.status == 1) {
            this.getList()
            this.$store.dispatch('getInfocheck')
          }
          this.$message({
            type: res.status == 1 ? 'success' : 'error',
            message: res.msg,
          })
          // this.getExamineStatusCount()
        }
      )
    },
    // 上架
    putshelf() {
      let idarr = []
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要上架的数据')
        return
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].examine_status == 2 ||
          this.multipleSelection[i].examine_status == 0 ||
          this.multipleSelection[i].status == 1
        ) {
          this.$message.warning('请选择未上架的数据上架')
          return
        }
        // if (this.multipleSelection[i].examine_status == 0) {
        //   this.$message.warning('请选择未上架的数据上架')
        //   return
        // }
        if (
          this.multipleSelection[i].examine_status == 1 &&
          this.multipleSelection[i].status == 0
        ) {
          idarr.push(this.multipleSelection[i].id)
        }
        // idarr.push(this.multipleSelection[i].id)
      }
      this.$confirm('此操作将会直接上架厂家信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.onOrOffTheShelfCloudFactory(
            {
              id: idarr,
              status: 1,
            },
            (res) => {
              if (res.status == 1) {
                this.getList()
                this.$message({
                  type: 'success',
                  message: '上架成功!',
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `取消上架`,
          })
        })
    },
    // 下架
    outshelf() {
      let idarr = []
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要下架的数据')
        return
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].examine_status == 2) {
          this.$message.warning('请选择已经上架的数据下架')
          return
        }
        if (
          this.multipleSelection[i].examine_status == 1 &&
          this.multipleSelection[i].status == 0
        ) {
          this.$message.warning('请选择已经上架的数据下架')
          return
        }
        if (
          this.multipleSelection[i].examine_status == 0 &&
          this.multipleSelection[i].status == 0
        ) {
          this.$message.warning('请选择已经上架的数据下架')
          return
        }
        if (
          this.multipleSelection[i].examine_status == 1 &&
          this.multipleSelection[i].status == 1
        ) {
          idarr.push(this.multipleSelection[i].id)
        }
        // idarr.push(this.multipleSelection[i].id)
      }
      this.$confirm('此操作将会直接下架厂家信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.onOrOffTheShelfCloudFactory(
            {
              id: idarr,
              status: 0,
            },
            (res) => {
              if (res.status == 1) {
                this.getList()
                this.$message({
                  type: 'success',
                  message: '下架成功!',
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `取消下架`,
          })
        })
    },
    // 删除
    handledelete() {
      let idarr = []
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择你需要删除的数据')
        return
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].status == 0) {
          idarr.push(this.multipleSelection[i].id)
        }
        if (this.multipleSelection[i].status == 1) {
          this.$message.warning('已上架的不能删除')
          return
        }
        if (
          this.multipleSelection[i].status == 0 &&
          this.multipleSelection[i].examine_status == 0
        ) {
          this.$message.warning('待审核的数据的不能删除')
          return
        }
      }
      // if (row.status == 0) {
      //   idarr.push(row.id)
      // }
      // if (row.status == 1) {
      //   this.$message.warning('已上架的不能删除')
      //   return
      // }

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$request.deletefactoryasync(
            {
              id: idarr,
            },
            (res) => {
              if (res.status == 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
                this.getList()
                // this.getExamineStatusCount()
              } else {
                this.$message.error('上架的不能删除,删除失败')
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    handleOrder(row) {
      this.currentOrderInfo = row
      if (row.share_status != 1) {
        // 查看详情
        this.orderDetalDialogShow = true
      } else {
        // 处理回访
        this.handleDialogShow = true
      }
    },
    handleCurrentChange(page) {
      this.payload.page = page
      if (this.payload.filter) {
        this.searchBtn()
      } else {
        this.getList()
      }
    },
    // 获取表格列表
    getList() {
      console.log('this.payload', this.payload)
      this.$request.getCloudFactoryList(this.payload, (res) => {
        console.log('云工厂信息获取成功', res)
        this.total_count = res.data.total
        this.tableData = res.data.data
      })
    },
    // 获取创建者
    getCreatList() {
      this.$request.getCreateName({}, (res) => {
        console.log('创建者获取成功', res)
        let filetr = []
        for (let i = 0; i < res.data.length; i++) {
          let obj = {
            text: '',
            value: null,
          }
          obj.text = res.data[i].name
          obj.value = res.data[i].id
          filetr.push(obj)
        }
        this.creatNameList = filetr
      })
    },
  },
  mounted() {
    this.getList()
    this.getCreatList()
  },
}
</script>

<style lang="less" scoped>
.experience_order {
  padding: 12px;
  .order_status_nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    > div {
      position: relative;
      bottom: -1px;
      padding: 12px 24px;
      cursor: pointer;
      user-select: none;
      .customer_num {
        position: absolute;
        background: red;
        color: white;
        font-size: 12px;
        line-height: 12px;
        border-radius: 4px;
        padding: 1px 3px;
        top: 5px;
      }
      &.active {
        border-bottom: 2px solid #1890ff;
        color: #1890ff;
      }
    }
    .nohandle {
      position: relative;
      > span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #f5222d;
        position: absolute;
        display: inline-block;
        top: 8px;
        left: 50%;
      }
    }
  }
  .search_wrap {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    /deep/ .el-select .el-input {
      width: 130px;
    }
    /deep/ .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    /deep/ .el-input-group__append {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
    .mb-4 {
      .mb-4-elbtn {
        color: white;
        background: #6476ffff !important;
        border: none;
      }
      .mb-4-btn {
        margin-left: 10px;
        cursor: pointer;
        background: #ffffff;
        color: #000000 90%;
        font-size: 14px;
        width: 60px;
        height: 32px;
        width: 60px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #dcdcdc;
      }
    }
  }
  .manage_order_list_warp {
    .rowapper {
      display: flex;
      .titleimg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .name {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #6476ff;
        line-height: 22px;
      }
      .addres {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }
    }
    .do_up,
    .do_edit,
    .do_del {
      margin-right: 5px;
      color: rgb(21, 91, 212);
      cursor: pointer;
    }
    .cirle {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: #00a870;
      border-radius: 50%;
      opacity: 1;
      margin-right: 5px;
    }
    .upcir {
      font-size: 14px;
      margin-right: 5px;
      font-weight: 400;
      color: #00a870;
      line-height: 22px;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .orderDetalDialog_wrap {
    p {
      margin-bottom: 10px;
    }
  }
  .handleDialog_wrap {
  }
}
</style>
