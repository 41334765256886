import { render, staticRenderFns } from "./information-review.vue?vue&type=template&id=1499c203&scoped=true&"
import script from "./information-review.vue?vue&type=script&lang=js&"
export * from "./information-review.vue?vue&type=script&lang=js&"
import style0 from "./information-review.vue?vue&type=style&index=0&id=1499c203&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1499c203",
  null
  
)

export default component.exports